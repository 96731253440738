import { useEffect, useState, useMemo } from 'react';
import CreditCards from 'creditcards';

// Functions
import loadScript from 'pstv-commerce-tools/functions/load-script'

// Services
import masterpassServices from '/services/masterpass'

const createIyzicoParams = ({ info , storeSaleId }) => {
	return {
		additionalParams: {
			"order_price_arr": [info.amount],
			"order_shipping": "0",
			"order_product_name_arr": ["NAME1"],
			"order_product_code_arr": ["CODE1"],
			"order_qty_arr": ["1"],
			"order_product_info_arr": ["INFO1"],
			"bill_first_name": "-",
			"bill_last_name": "-",
			"bill_email": "test@test.com",
			"delivery_email": "testa@test.com",
			"bill_phone": "901111111111",
			"bill_country_code": "TR",
			"bill_fax": "",
			"bill_address": "-",
			"bill_address2": "-",
			"bill_zip_code": "12923",
			"bill_city": "ISTANBUL",
			"bill_state": "ISTANBUL",
			"delivery_first_name": "-",
			"delivery_last_name": "-",
			"delivery_phone": "901111111111",
			"delivery_company": "-",
			"delivery_address": "-",
			"delivery_address2": "-",
			"delivery_zip_code": "12923",
			"delivery_city": "ISTANBUL",
			"delivery_state": "ISTANBUL",
			"delivery_country_code": "TR",
			"number_time": "214",
			"owner_time": "2342",
			"order_timeout": "124",
			"card_program_name": "",
			"order_discount": "0",
			"order_price_type_arr": ["PHYSICAL"],
			"order_vat_arr": ["0"],
			"order_ver_arr": ["1"],
			"order_mplace_merchant_arr": ["mplace1" ],
			"client_ip": "127.0.0.2",
			"client_time": "125500",
			"basket_id": storeSaleId.toString(),
			"vendor_id": storeSaleId.toString(),
			"buyer_id": storeSaleId.toString(), // member ID basalım
			"identity_number": "11111111111",
			"last_login_date": "2013-04-21 15:12:09",
			"registration_date": "2013-04-21 15:12:09",
			"commission_Rate": "0",
			"payment_content": "Metro Ödeme",
			"transaction_description": "Metro Ödeme"
		}
	}
}

// Async Deps
const importCashScript = () => {
	return new Promise(resolve => {
		if(window.$) {
			resolve();
		}
		else {
			import('cash-dom').then((cashModule) => {
				window.$ = cashModule.default;
				resolve();
			});
		}
	});
}

const importMFSScript = () => {
	return new Promise(resolve => {
		if(!window.MFS){
			loadScript(
				'/assets/scripts/mfs-client.min.js',
				() => { return window.MFS ? true : false; }
			).then(() => {
				resolve();
			});
		}
		else {
			resolve();
		}
	})
}

const parseAccountStatus = (statusKey) => {
	const status = {
		raw: statusKey,
		inProgramme: statusKey[0] === "1", // Son kullanıcının müşteri programında kayıtlı kartı var mı?
		isRegistered: statusKey[1] === "1", // Son kullanıcın Masterpass hesabı var mı?
		hasCards: statusKey[2] === "1", // Son kullanıcının Masterpass hesabında kayıtlı kart var mı?
		isLinked: statusKey[3] === "1", // Son kullanıcın Masterpass hesabı müşteri ile ilişkilendirilmiş mi?
		isLocked: statusKey[4] === "1", // Son kullanıcının Masterpass hesabı kilitli mi?
		phoneUpdated: statusKey[5] === "1", // Son kullanıcının Telefon Numarası başka bir üye işyerinde güncellendi mi?
		hasDebitCards: statusKey[6] === "1", // Son kullanicin kredi karti veya müsteri programinin destekledigi debit kart var mi ?
	}

	let mode = 'register';
	
	if(status.isLocked) {
		mode = 'locked';
	}
	else if(status.isRegistered && status.isLinked && !status.phoneUpdated) {
		mode = 'ok'
	}

	return {
		...status,
		mode: mode,
	}
}

const parseCardStatus = (statusKey) => {
	const status = {
		raw: statusKey,
		isSupportedType: statusKey[4] === "1", // Kredi kartı veya Saticinin desteklediği debit kart mi?
		isExpired: statusKey[5] === "1", // Kullanım suresi dolmuş kart mi?
		isDebit: statusKey[6] === "1", // Debit kart mi?
		isPrivileged: statusKey[7] === "1", // Öncelikli kart mi?
		isCorporate: statusKey[8] === "1", // Kurumsal kart mi?
		isOtp: statusKey[9] === "1", // Kartın bankası OTP destekliyor mu?
	}

	return {
		...status,
	}
}

export const useMasterpass = ({ saleId, storeSaleId, enabled }) => {
	const [error, setError] = useState(false);
	const [info, setInfo] = useState(false);
	const [clientToken, setClientToken] = useState(false);
	const [accountStatus, setAccountStatus] = useState(false);
	const [cards, setCards] = useState(false);

	const initialized = useMemo(() => {
		return !!info && !!clientToken && !!accountStatus;
	}, [info, clientToken, accountStatus])

	const refetch = () => {
		setClientToken(false);
		setCards(false);
		setInfo(false);
		setError(false);
	}

	const generateVirtualForm = (formData) => {
		if(window.$) {
			const form = document.createElement('form');
			for (const key in formData) {
				const inputElement = document.createElement('input');
				inputElement.name = key;
				inputElement.value = formData[key];
				inputElement.type = 'text';
				form.appendChild(inputElement);
			}
			return window.$(form);
		}
		else {
			return false;
		}
	}

	const initialize = (getNewToken = false) => {
		return new Promise((resolve, reject) => {
			setError(false);

			masterpassServices.getInfo(saleId).then((infoPayload) => {
				setInfo(infoPayload);
				window.MFS.setClientId(infoPayload.client_id);
				window.MFS.setAddress(infoPayload.client_sdk_url);
				window.MFS.setAdditionalParameters(createIyzicoParams({ storeSaleId, info: infoPayload }).additionalParams);

				if(!clientToken || getNewToken) {
					getClientToken().then(() => {
						resolve(infoPayload);
					}).catch((e) => {
						setError(e);
						reject(e);
					})
				}
				else {
					resolve(infoPayload);
				}
			}).catch((e) => {
				setError(e);
				reject(e);
			})
		});
	}

	const getClientToken = () => {
		return new Promise((resolve, reject) => {
			masterpassServices.generateClientToken(saleId).then((token) => {
				setClientToken(token);
				setTimeout(function() {
					resolve(token);
				}, 100);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	const checkStatus = () => {
		return new Promise((resolve, reject) => {
			try {
				if(info && clientToken) {
					window.MFS.checkMasterPass(generateVirtualForm({
						userId: info.msisdn,
						referenceNo: info.reference_no,
						sendSmsLanguage: info.send_sms_language,
						sendSms: info.send_sms,
						token: clientToken,
					}), (status, response) => {

						const newStatus = parseAccountStatus(response.accountStatus);
						setAccountStatus(newStatus);
	
						if(newStatus.isLinked && newStatus.isRegistered && newStatus.hasCards) {
							getCards().then((cards) => {
								resolve({ cards: cards, accountStatus: newStatus });
							}).catch(() => {
								resolve({ cards: [], accountStatus: newStatus })
							})
						}
						else {
							resolve({ cards: [], accountStatus: newStatus });
						}
					});
				}
				else {
					reject('Token veya MP bilgisi mevcut değil.');
				}
			}
			catch(e) {
				reject(e);
			}
		})
	}

	const linkAccount = () => {
		return new Promise((resolve, reject) => {
			window.MFS.linkCardToClient(generateVirtualForm({
				msisdn: info.msisdn,
				referenceNo: info.reference_no,
				sendSmsLanguage: info.send_sms_language,
				sendSms: info.send_sms,
				token: clientToken,
			}), (status, response) => {
				if(['0000', ''].includes(response.responseCode)) {
					reject('Burada aslında onaylandı, refresh falan lazım');
				}
				else if(['5001', '5008'].includes(response.responseCode)) {
					resolve();
				}
				else {
					reject(response.responseDescription || 'Hesabınız eşleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
				}
			});
		});
	}

	const confirmOtp = (pin, pinType = 'otp', data = { }) => {
		return new Promise((resolve, reject) => {
			window.MFS.validateTransaction(generateVirtualForm({
				validationCode: pin,
				referenceNo: info.reference_no,
				sendSmsLanguage: info.send_sms_language,
				sendSms: info.send_sms,
				pinType: pinType
			}), (status, response) => {
				if(['0000', ''].includes(response.responseCode)) {
					if(data?.cardName && data?.isPurchase && response.transactionId && response.token) {
						masterpassServices.validatePayment(saleId, response.token).then((response) => {
							window.localStorage.setItem('last_used_card_alias', data.cardName);
							resolve({ ...response, validation: false });
						}).catch((error) => {
							reject(error);
						})
					}
					else {
						resolve({ ...response, validation: false });
					}
				}
				else if(['5001', '5008'].includes(response.responseCode)) {
					resolve({ ...response, validation: 'otp' })
				}
				else if(['5002'].includes(response.responseCode)) {
					resolve({ ...response, validation: 'mpin' })
				}
				else {
					reject(response.responseDescription || 'Telefon doğrulaması yapılırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
				}
			});
		});
	}

	const getCards = () => {
		return new Promise((resolve, reject) => {
			window.MFS.listCards(info.msisdn, clientToken, (statusCode, response) => {
				if (response.responseCode !== "0000" && (response.responseCode !== "" || (response.cards && response.cards.length > 0)) && response.cards && response.cards.length)
				{
					const cards = response.cards.map((cc) => {
						let bin = cc.Value1.substring(0, 6);

						return {
							id: cc.UniqueId,
							maskedNo: cc.Value1,
							bin: bin,
							type: CreditCards.card.type(bin, true),
							name: cc.Name,
							enpara: ["401072", "526911", "479679", "498749", "535177"].includes(bin),
							...parseCardStatus(cc.CardStatus),
						}
					});

					setCards(cards);
					resolve(cards);
				}
				else {
					reject(response.responseDescription || 'Kartlarınız getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
				}

			});
		});
	}

	const addCard = (cardInfo) => {
		return new Promise((resolve, reject) => {
			if(cards && cards.find(c => c.name === cardInfo.name)) {
				reject(`${cardInfo.name} isminde kayıtlı bir kartınız var. Lütfen başka bir kart ismi girin.`)
			}
			else {
				const [expiryMonth, expiryYear] = cardInfo.expiry.split('/');

				window.MFS.register(generateVirtualForm({
					rtaPan: cardInfo.cardNumber,
					cvc: cardInfo.cvv,
					accountAliasName: cardInfo.name,
					expiryDate: expiryYear + expiryMonth,

					msisdn: info.msisdn,
					token: clientToken,
					referenceNo: info.reference_no,
					sendSmsLanguage: info.send_sms_language,
					sendSms: info.send_sms,

					actionType: 'A',
					clientIp: info.client_ip,
					delinkReason: '',
					eActionType: 'A',
					cardTypeFlag: '05',
					cpinFlag: 'Y',
					defaultAccount: 'Y',
					mmrpConfig: '110010',
					identityVerificationFlag: 'Y',
					mobileAccountConfig: 'MWA',
					timeZone: '+01',
					uiChannelType: '6',
				}), (statusCode, response) => {
					if(['5001', '5008'].includes(response.responseCode)) {
						resolve({ showOTP: true })
					}
					else if(['0000', ''].includes(response.responseCode)) {
						resolve({ showOTP: false })
					}
					else {
						reject(response.responseDescription || 'Kart kaydedilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
					}
	
				});
			}
		});
	}

	const removeCard = (cardName) => {
		return new Promise((resolve, reject) => {
			if(cards && !cards.find(c => c.name === cardName)) {
				reject(`${cardName} isminde kayıtlı bir kartınız yok. Lütfen tekrar deneyin.`)
			}
			else {
				window.MFS.deleteCard(generateVirtualForm({
					accountAliasName: cardName,

					msisdn: info.msisdn,
					token: clientToken,
					referenceNo: info.reference_no,
					sendSmsLanguage: info.send_sms_language,
					sendSms: info.send_sms,
				}), (statusCode, response) => {
					if(['0000', ''].includes(response.responseCode)) {
						getCards().then((cards) => {
							resolve(cards);
						}).catch(() => {
							checkStatus().then(() => {
								resolve([])
							}).catch(e => {
								reject(e)
							})
						});
					}
					else {
						reject(response.responseDescription || 'Kart silinirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
					}
	
				});
			}
		});
	}
	

	const purchaseWithRegisteredCard = (cardName, options) => {
		const { installments }  = {
			...{
				installments: 1
			},
			...options ?? {},
		};

		return new Promise((resolve, reject) => {
			if(cards && !cards.find(c => c.name === cardName)) {
				reject(`${cardName} isminde kayıtlı bir kartınız yok. Lütfen tekrar deneyin.`)
			}
			else {
				masterpassServices.generatePaymentToken(saleId).then((paymentToken) => {
					try {
						window.MFS.purchase(generateVirtualForm({
							amount: info.amount,
							listAccountName: cardName,
		
							msisdn: info.msisdn,
							token: paymentToken,
							referenceNo: info.reference_no,
	
							macroMerchantId: info.macro_merchant_id,
							orderNo: storeSaleId,
							installmentCount: installments,
	
							aav: 'aav',
							clientIp: info.client_ip,
							clientId: info.client_id,
							encCPin: '0',
							encPassword: '',
							sendSmsMerchant: 'Y',
							password: '',
	
	
							sendSmsLanguage: info.send_sms_language,
							sendSms: info.send_sms,
						}), (statusCode, response) => {
							if(['0000', ''].includes(response.responseCode)) {
								masterpassServices.validatePayment(saleId, response.token).then((response) => {
									window.localStorage.setItem('last_used_card_alias', cardName);
									resolve({ ...response, validation: false });
								}).catch((error) => {
									reject(error);
								})
							}
							else if (response.responseCode === "5001") {
								resolve({ validation: 'otp' });
							}
							else if (response.responseCode === "5002") {
								resolve({ validation: 'mpin' })
							}
							else if (response.responseCode === "5010") {
								window.localStorage.setItem('last_used_card_alias', cardName);
								window.location.assign(response.url3D + "&returnUrl=" + info.return_3d_url);
							}
							else {
								reject(response.responseDescription || 'Kart silinirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.')
							}
			
						});
					}
					catch(e) {
						reject(e);
					}
				}).catch((e) => {
					reject(e);
				})
			}
		});
	}

	useEffect(() => {
		if(enabled && !initialized && saleId && storeSaleId) {
			Promise.all([
				importMFSScript(),
				importCashScript()
			]).then(() => {
				initialize();
			})
		}
		else if(!saleId) {
			// Flush data if no sale ID available.
			setError(false);
			setInfo(false);
			setClientToken(false);
			setCards(false);
			setAccountStatus(false);
		}
	}, [enabled, initialized, saleId, storeSaleId])

	useEffect(() => {
		if(!cards && info && clientToken) {
			checkStatus().then(() => {
			}).catch(() => {})
		}
	}, [info, clientToken])

	return {
		info,
		error,
		initialized,

		accountStatus,
		cards,

		initialize,
		refetch,
		linkAccount,
		confirmOtp,
		getCards,
		checkStatus,
		addCard,
		removeCard,
		purchaseWithRegisteredCard,
	}
}
