import { useEffect } from 'react';
import { setHeaders } from 'pstv-commerce-tools/utilities/request';
import { useStorage } from 'pstv-commerce-tools/utilities/storage';

export const RequestHeadersController = () => {
	const { storeId, guestToken, authToken } = useStorage();

	useEffect(() => {
		setHeaders(process.config.defaultHeaders);
	}, [])

	useEffect(() => {
		setHeaders({
			'store-id': storeId,
		})
	}, [storeId])

	useEffect(() => {
		setHeaders({
			'guest-token': guestToken,
		})
	}, [guestToken])

	useEffect(() => {
		setHeaders({
			'authorization': authToken ? `Bearer ${authToken}` : false,
		})
	}, [authToken])

	return null;
}
