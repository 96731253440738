import request from 'pstv-commerce-tools/utilities/request';

const basketServices = {
	get: () => {
		return new Promise((resolve, reject) => {
			request.get('basket', false, function (payload, status, request) {
				if (payload) {
					resolve({
						...payload,
						products: payload.products.map(p => (
							{
								...p,
								basket_quantity: parseFloat(parseFloat(p.basket_quantity).toFixed(3)),
							}
						)),
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	clear: () => {
		return new Promise((resolve, reject) => {
			request.get('basket/clear', false, function (payload, status, request) {
				if ([200].includes(status)) {
					resolve();
				}
				else {
					reject(request?.data?.meta?.messages || 'Sepetiniz temizlenirken bir hata ile karşılaşıldı.');
				}
			});
		});
	},
	addProduct: (serialID, productID, quantity) => {
		return new Promise((resolve, reject) => {
			request.post('basket', { product_id: productID, product_serial_id: serialID, quantity: quantity }, function (payload, status, request) {
				if (payload) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Ürün sepete eklenirken bir hata ile karşılaşıldı.');
				}
			});
		});
	},
	removeProduct: (basketId) => {
		return new Promise((resolve, reject) => {
			request.delete(`basket/${basketId}`, { }, function (payload, status, request) {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Ürün sepetten çıkarılırken bir hata ile karşılaşıldı.');
				}
			});
		});
	},
}

export default basketServices;
