import { useState, useEffect, useMemo, useRef, useCallback } from 'react';

// Controllers
import { useBasket, useBasketProduct } from '.';
import { useModals } from 'pstv-commerce-tools/utilities/modals';

// Functions
import formatNumber from 'pstv-commerce-tools/functions/format-number';

export const useBasketProductControls = (product) => {
	const productSerial = useMemo(() => (product?.serial_id), [product]);

	const { busy, updateProductQuantity: updateBasketQuantity } = useBasket();
	const { openModal } = useModals();
	
	const basketProduct = useBasketProduct(productSerial);
	const basketQuantityRef = useRef(basketProduct?.basket_quantity ?? 0);
	const basketQuantity = useMemo(() => {
		const newQuantity = basketProduct?.basket_quantity ?? 0;
		basketQuantityRef.current = newQuantity;
		return newQuantity;
	}, [basketProduct])
	const [quantity, setQuantity] = useState(basketQuantity);

	const increaseQuantity = useCallback((e) => {
		e.preventDefault();
		if(product) {
			checkAndSetQuantity(quantity + product.add_basket_size, true, quantity);
		}
	}, [quantity, product, checkAndSetQuantity]);
	
	const decreaseQuantity = useCallback((e) => {
		e.preventDefault();
		if(product) {
			checkAndSetQuantity(quantity - product.add_basket_size, false, quantity);
		}
	}, [quantity, product, checkAndSetQuantity]);

	const checkAndSetQuantity = useCallback((newQuantity, increase, prevQuantity) => {
		if(product) {
			if(increase && prevQuantity === 0) {
				const startAmount = Math.max(product.min_basket_add_amount, product.min_basket_amount);
				newQuantity = (Math.ceil(startAmount / product.add_basket_size) * product.add_basket_size);
			}
			else if(newQuantity < product.min_basket_amount) {
				newQuantity = increase ? (Math.ceil(product.min_basket_amount / product.add_basket_size) * product.add_basket_size) : 0;
			}
			else {

				const maxAddable = Math.min(product.quantity, product.max_basket_add_amount);
				const maxAddableString = parseInt(maxAddable) !== parseFloat(maxAddable) ? formatNumber(maxAddable) : maxAddable;

				if(newQuantity > maxAddable) {
					if(increase) {
						openModal('message', { message: maxAddable > 0 ? `Bu üründen sepetinize maksimum ${maxAddableString} ${product.unit.title ?? 'Adet'} ekleyebilirsiniz.` : 'Bu ürünün yeterince stoğu olmadığı için sepetinize eklenemiyor.' });
					}
					newQuantity = Math.floor(maxAddable / product.add_basket_size) * product.add_basket_size;
				}
			}
			setQuantity(newQuantity);
		}
	}, [product]);

	const [quantityDisplay, quantityIsMinimum] = useMemo(() => {
		if(product) {
			return [
				formatNumber(quantity, { forceDecimals: false }),
				quantity <= product.min_basket_amount,
			]
		}
		else {
			return ['', false];
		}
	}, [quantity, product])

	useEffect(() => {
		setQuantity(basketProduct?.basket_quantity ?? 0);
	}, [basketProduct])

	useEffect(() => {
		updateBasketQuantity(product, quantity).catch(() => {
			setQuantity(basketQuantityRef.current);
		})
	}, [quantity, product])

	return {
		product,
		quantity,
		quantityDisplay,
		increaseQuantity,
		decreaseQuantity,
		setQuantity: checkAndSetQuantity,
		quantityIsMinimum,
		busy,
	}
}
