import { useEffect } from "react";

// Context
import { useGlobalState } from "pstv-commerce-tools/utilities/global-state";

// Services
import siteServices from '/services/site';

// Utils
import deepFindCategory from '/utilities/deep-find-category';

const getCategories = () => {
	return siteServices.getCategories();
}

// const getCampaigns = () => {
// 	return siteServices.getCampaigns();
// }

const getDeliveryLimits = () => {
	return siteServices.getDeliveryLimits();
}

const getHomeSlogan = () => {
	return siteServices.getHomeSlogan();
}

export const GlobalStateController = () => {
	const { categories, deliveryLimits, homeSlogan, setData } = useGlobalState()

	// CSR fallback.
	useEffect(() => {
		if(!categories) {
			getCategories().then((categories) => {
				const petSubCategory = deepFindCategory(categories, 500, 756)
				const modifiedCategories = [...categories, petSubCategory].filter(obj => obj);

				setData('categories', modifiedCategories);
			});
		}
	}, [categories])

	useEffect(() => {
		if(!deliveryLimits) {
			getDeliveryLimits().then((deliveryLimits) => {
				setData('deliveryLimits', deliveryLimits);
			});
		}
	}, [deliveryLimits])

	useEffect(() => {
		if(!homeSlogan) {
			getHomeSlogan().then((homeSlogan) => {
				setData('homeSlogan', homeSlogan);
			});
		}
	}, [homeSlogan])

	// useEffect(() => {
	// 	if(!campaigns) {
	// 		getCampaigns().then((campaigns) => {
	// 			setData('campaigns', campaigns);
	// 		});
	// 	}
	// }, [campaigns])

	return null;
}

GlobalStateController.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getCategories(),
			getDeliveryLimits(),
			getHomeSlogan(),
			// getCampaigns(),
		]).then(([categories, deliveryLimits, homeSlogan]) => {
			const petSubCategory = deepFindCategory(categories, 500, 756)
			const modifiedCategories = [...categories, petSubCategory].filter(obj => obj);

			resolve({
				categories: modifiedCategories,
				deliveryLimits,
				homeSlogan,
				// campaigns,
			})
		}).catch(e => {
			console.error('Initial props error: ', e);
			resolve({
			})
		})
	})
}
