import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { BasketContext } from './basket-context';
import { defaultState } from '.';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import useDebounce from 'pstv-commerce-tools/hooks/debounce';

// Services
import basketServices from '/services/basket';

// Context
import { useDeliveryInfo } from '/controllers/delivery';
import { useStorage } from 'pstv-commerce-tools/utilities/storage';
import { useModals } from 'pstv-commerce-tools/utilities/modals';
import { useAuth } from 'pstv-commerce-tools/utilities/auth';
import { useGlobalEvents } from 'pstv-commerce-tools/utilities/global-events';

const useProductAnalytics = ((indexedProducts, initialized) => {
	const previousProducts = useRef(false);
	const { triggerEvent } = useGlobalEvents();

	useEffect(() => {
		if(initialized) {
			if(previousProducts.current) {
				const flatProducts = {
					...Object.entries(previousProducts.current).reduce((products, [serial, product]) => ({
						...products,
						[serial]: {
							...product,
							basket_quantity: 0,
						}, 
					}),
					{}),
					...indexedProducts,
				}

				for(const [serial, product] of Object.entries(flatProducts)) {
					const oldQuantity = previousProducts.current[serial]?.basket_original_quantity ? parseFloat(previousProducts.current[serial].basket_original_quantity) : 0;
					const newQuantity = parseFloat(product.basket_original_quantity);

					if(newQuantity > oldQuantity) {
						triggerEvent('productBasketAdd', { product, quantity: parseFloat((newQuantity - oldQuantity).toFixed(3)) });
						triggerEvent('productBasketQuantityChange', { product, quantity: newQuantity });
					}
					else if(oldQuantity > newQuantity) {
						triggerEvent('productBasketRemove', { product, quantity: parseFloat((oldQuantity - newQuantity).toFixed(3)) });
						triggerEvent('productBasketQuantityChange', { product, quantity: newQuantity });
					}
				}
			}
	
			previousProducts.current = indexedProducts;
		}
	}, [indexedProducts, initialized])
})

export const BasketProvider = ({ children }) => {
	const { selectedDelivery } = useDeliveryInfo();
	const { loggedIn } = useAuth();
	const { openModal } = useModals();
	const { authToken, storeId } = useStorage();

	const [initialized, setInitialized] = useState(defaultState.initialized);
	const [products, setProducts] = useState(defaultState.products);
	const [summary, setSummary] = useState(defaultState.summary);
	const [deliveryLimits, setDeliveryLimits] = useState(defaultState.deliveryLimits);
	const [fetchedStoreId, setFetchedStoreId] = useState(false);
	const [basketTimestamp, setBasketTimestamp] = useState(false);
	const [busy, setBusy] = useState(false);

	const updateQueueRef = useRef({})

	const [updateQueue, setUpdateQueueRaw] = useState(updateQueueRef.current);
	const setUpdateQueue = (newQueue) => {
		updateQueueRef.current = newQueue;
		setUpdateQueueRaw(updateQueueRef.current);
	}
	const debouncedUpdateQueue = useDebounce(updateQueue, 500, updateQueueRef.current);

	const indexedProducts = useMemo(() => {
		const newIndexedProducts = {}
		for(const product of products) {
			newIndexedProducts[product.serial_id] = {...product};
		}

		return newIndexedProducts;
	}, [products])

	useProductAnalytics(indexedProducts, initialized);

	useEffect(() => {
		const serials = Object.keys(debouncedUpdateQueue);
		if(serials.length > 0) {
			setBusy(true);

			const promises = serials.map(serial => {
				return (debouncedUpdateQueue[serial].quantity > 0 ?
					basketServices.addProduct(serial, debouncedUpdateQueue[serial].id, debouncedUpdateQueue[serial].quantity)
					:
					indexedProducts[serial] ? basketServices.removeProduct(indexedProducts[serial].basket_id) : (() => (new Promise((resolve) => { resolve(true); })))()
				)
			});

			Promise.allSettled(promises).catch(() => {}).then((data) => {
				const messages = data.map(r => r.reason ? r.reason : false).reduce((messages, reason) => {
					const newMessages = typeof reason === 'string' ? [reason] : (reason?.error ?? []);
					return [...newMessages, ...messages];
				}, [])

				if(messages.length) {
					openModal('message', { message: messages.join(', ') });
				}
				getBasketData().catch(() => {
				}).finally(() => {
					setBusy(false);
					setUpdateQueue({});
				})
			})
		}
	}, [debouncedUpdateQueue, getBasketData])

	useEffect(() => {
		if(!initialized && authToken) {
			getBasketData();
		}
		else if(initialized && !authToken) {
			resetBasketData();
		}
	}, [initialized, authToken, getBasketData, resetBasketData]);

	useEffect(() => {
		if(fetchedStoreId && storeId !== fetchedStoreId) {
			clearBasket();
		}
	}, [fetchedStoreId, storeId, clearBasket])

	const resetBasketData = useCallback(() => {
		setProducts(defaultState.products);
		setSummary(defaultState.summary);
		setDeliveryLimits(defaultState.deliveryLimits);
		setFetchedStoreId(storeId);
		setInitialized(false);
	}, [storeId]);

	const getBasketData = useCallback(() => {
		return new Promise((resolve, reject) => {
			basketServices.get().then(basketData => {
				setProducts(basketData.products);
				setSummary(basketData.summary);
				setDeliveryLimits(basketData.delivery_limits);
				setFetchedStoreId(storeId);
				setInitialized(true);
				resolve(basketData);
			}).catch((e) => {
				reject(e);
			}).finally(() => {
				setBasketTimestamp(new Date().getTime().toString());
			})
		});
	}, [storeId]);

	const clearBasket = useCallback(() => {
		return new Promise((resolve) => {
			setBusy(true);
			basketServices.clear().catch((feedback) => {
				openModal('message', { feedback: feedback });
			}).finally(() => {
				getBasketData().then((basketData) => {
					setBusy(false);
					resolve(basketData);
				});
			})
		});
	}, [getBasketData]);

	const updateProductQuantity = (product, quantity) => {
		return new Promise((resolve, reject) => {
			const productSerial = product.serial_id;
			// No changes on quantity compared to latest fetched basket.
			if(quantity === 0 && !indexedProducts[productSerial] || quantity === indexedProducts[productSerial]?.basket_quantity) {
				setUpdateQueue(omit(updateQueueRef.current, productSerial));
				resolve();
			}
			else if(loggedIn && selectedDelivery) {
				setUpdateQueue({ ...updateQueueRef.current, [productSerial]: { quantity: quantity, id: product.id } });
				resolve();
			}
			else if(!loggedIn) {
				openModal('auth');
				reject();
			}
			else if(!selectedDelivery) {
				openModal('address', { message: 'Sepetinize ürün eklemek için bir teslimat adresi seçmelisiniz.' });
				reject();
			}
		})
	}

	return <BasketContext.Provider value={{
		initialized,
		products,
		summary,
		deliveryLimits,
		indexedProducts,
		busy,
		updateProductQuantity,
		clearBasket,
		getBasketData,
		basketTimestamp,
	}}>
		{children}
	</BasketContext.Provider>
}

BasketProvider.propTypes = {
	children: PropTypes.node,
}
