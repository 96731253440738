import '/assets/styles/app.scss';
import useServerProps from 'pstv-commerce-tools/hooks/server-props';
import PropTypes from 'prop-types'
// import loadable from '@loadable/component';

// Data
import routes from '/routes'
import layouts from '/views/layouts'

import { RequestHeadersController } from '/controllers/request-headers';
import { GlobalStateController } from '/controllers/global-state';
import { ModalsProvider, ModalsRenderer } from 'pstv-commerce-tools/utilities/modals';
import { GtmEventsController } from './controllers/analytics';

import { NavigatorProvider, NavigatorRoutes } from 'pstv-commerce-tools/utilities/navigator';
import { AuthProvider } from '/utilities/auth';
import { BasketProvider } from '/controllers/basket';
import { DeliveryProvider } from '/controllers/delivery';
import { CheckoutProvider } from '/utilities/checkout';
import { FormsProvider } from 'pstv-commerce-tools/partials/forms';
import { StorageProvider } from 'pstv-commerce-tools/utilities/storage';
import { GlobalStateProvider } from 'pstv-commerce-tools/utilities/global-state';
import { BreakpointsProvider } from 'pstv-commerce-tools/utilities/breakpoints';
import { Messenger } from 'pstv-commerce-tools/utilities/messenger';
import { HeadProvider } from 'pstv-commerce-tools/utilities/head'
import { GlobalEventsProvider } from 'pstv-commerce-tools/utilities/global-events';
import { LazySentryProvider } from '/utilities/sentry';

// Partials
import Loader from '/views/partials/loader';
import Icon from 'pstv-commerce-tools/partials/icon';

// Modals
import modals from '/views/modals';

// Lazy Components
// const CookieDisclaimer = loadable(() => import('/views/sections/cookie-disclaimer'), { ssr: false });

const ModalCloseBtn = <button className="modal-closebtn">
	<Icon name="close" />
</button>;

const App = ({serverProps: ssrProps}) => {
	const serverProps = useServerProps(ssrProps);

	return (
		<LazySentryProvider>
			<ModalsProvider config={{
				layers: 2,
				styles: () => (
					import('/assets/styles/modals/modals.scss')
				),
				modals: modals,
				closeBtn: ModalCloseBtn,
			}}>
				<GlobalStateProvider initialData={serverProps?.sharedData}>
					<GlobalEventsProvider>
						<StorageProvider initialCookies={serverProps?.cookies}>
							<RequestHeadersController />
							<BreakpointsProvider initialState={serverProps?.mediaStatus}>
								<AuthProvider>
									<DeliveryProvider>
										<BasketProvider>
											<FormsProvider>
												<HeadProvider serverProps={serverProps}>
													<NavigatorProvider
														serverProps={serverProps}
														pageLoader={<Loader />}
														routes={routes}
														layouts={layouts}>
														<CheckoutProvider>
															<GtmEventsController />
															<ModalsRenderer />
															<GlobalStateController />
															<Messenger />
															<NavigatorRoutes />
															{/* <CookieDisclaimer /> */}
														</CheckoutProvider>
													</NavigatorProvider>
												</HeadProvider>
											</FormsProvider>
										</BasketProvider>
									</DeliveryProvider>
								</AuthProvider>
							</BreakpointsProvider>
						</StorageProvider>
					</GlobalEventsProvider>
				</GlobalStateProvider>
			</ModalsProvider>
		</LazySentryProvider>
	)
}

App.propTypes = {
	serverProps: PropTypes.object
}

export default App;
