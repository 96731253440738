import request from 'pstv-commerce-tools/utilities/request';

const userServices = {
	getUserData: () => {
		return new Promise((resolve, reject) => {
			request.get(`member/me`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.member
				) {
					resolve(payload.member);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getGuestToken: () => {
		return new Promise((resolve, reject) => {
			request.get(`users/init`, {}, (payload, status, request) => {
				if(payload?.guest_token) {
					resolve(payload.guest_token);
				}
				else {
					reject(request);
				}
			});
		});
	},
	logout: () => {
		return new Promise((resolve, reject) => {
			request.delete(`users/logout`, {}, (payload, status, request) => {
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Otp
	sendOTP: ({ phoneNumber, action = 'login', recaptcha = null }) => {
		return new Promise((resolve, reject) => {
			request.post(`otp/send`, {
				mobile_number: phoneNumber,
				token_type: `${action}_token`,
				recaptcha,
			}, (payload, status, request) => {
				if (status === 200) {
					resolve(true);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	checkOTP: (phoneNumber, code) => {
		return new Promise((resolve, reject) => {
			request.post(`otp/check`, {
				mobile_number: phoneNumber,
				code,
			}, (payload, status, request) => {
				if (status === 200 && payload.otp_token) {
					resolve(payload.otp_token);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS onaylanırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	authenticateOTP: (phoneNumber, otpToken) => {
		return new Promise((resolve, reject) => {
			request.post('users/authenticate', {
				identifier: phoneNumber,
				otp_token: otpToken,
			}, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Giriş işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Register
	registerUser: (userData) => {
		return new Promise((resolve, reject) => {
			request.post(`users/register`, {
				...userData,
			}, (payload, status, request) => {
				if (status === 200 && payload?.token) {
					resolve(payload.token);
				}
				else {
					reject(request?.data?.meta?.messages || 'Kayıt işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Info
	updateInfo: (userData) => {
		return new Promise((resolve, reject) => {
			request.put(`member`, {
				...userData,
			}, (payload, status, request) => {
				if (status === 200) {
					resolve(request?.data?.meta?.messages || { success: ['Üyelik bilgileriniz güncellendi.']});
				}
				else {
					reject(request?.data?.meta?.messages || 'Kayıt işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Addresses
	listAddresses: () => {
		return new Promise((resolve, reject) => {
			request.get(`delivery`, {}, (payload, status, request) => {
				if (status === 200 && payload?.deliveries) {
					resolve(payload.deliveries.map(delivery => ({
						...delivery,
						latitude: parseFloat(delivery.latitude),
						longitude: parseFloat(delivery.longitude),
					})));
				}
				else {
					reject(request?.data?.meta?.messages || 'Adresleriniz getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	saveAddress: (addressData) => {
		return new Promise((resolve, reject) => {
			request.post(`delivery`, {
				...addressData,
			}, (payload, status, request) => {
				if (status === 200 && payload?.delivery) {
					resolve(payload.delivery);
				}
				else {
					reject(request?.data?.meta?.messages || 'Adresiniz kaydedilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	updateAddress: (addressId, addressData) => {
		return new Promise((resolve, reject) => {
			request.put(`delivery/${addressId}`, {
				...addressData,
			}, (payload, status, request) => {
				if (status === 200 && payload?.delivery) {
					resolve(payload.delivery);
				}
				else {
					reject(request?.data?.meta?.messages || 'Adresiniz güncellenirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	getAddress: (addressId) => {
		return new Promise((resolve, reject) => {
			request.get(`delivery/${addressId}`, {}, (payload, status, request) => {
				if (status === 200 && payload?.delivery) {
					resolve({
						...payload.delivery,
						latitude: parseFloat(payload.delivery.latitude),
						longitude: parseFloat(payload.delivery.longitude),
					});
				}
				else {
					reject(request?.data?.meta?.messages || 'Adresiniz getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	deleteAddress: (addressId) => {
		return new Promise((resolve, reject) => {
			request.delete(`delivery/${addressId}`, {}, (payload, status, request) => {
				if (status === 200) {
					resolve(true);
				}
				else {
					reject(request?.data?.meta?.messages || 'Adresiniz silinirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Wishlists
	getWishlistProducts: () => {
		return new Promise((resolve, reject) => {
			request.get(`wishlists/favorite`, {}, (payload, status) => {
				if (status === 200 && payload?.items) {
					resolve(payload.items);
				}
				else {
					reject(false);
				}
			});
		});
	},
	addWishlistProduct: (product) => {
		return new Promise((resolve, reject) => {
			request.post(`wishlists/favorite`, { product_id: product.id, product_serial_id: product.serial_id, quantity: 1, quantity_type: 1 }, (payload, status) => {
				if (status === 201) {
					resolve(payload);
				}
				else {
					reject(false);
				}
			});
		});
	},
	removeWishlistProduct: (favProductId) => {
		return new Promise((resolve, reject) => {
			request.delete(`wishlists/items/${favProductId}`, { }, (payload, status) => {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(false);
				}
			});
		});
	},
	// Orders
	listOrders: () => {
		return new Promise((resolve, reject) => {
			request.get(`member/orders`, {}, (payload, status, request) => {
				if (status === 200 && payload?.orders) {
					resolve(payload.orders)
				}
				else {
					reject(request?.data?.meta?.messages || 'Siparişleriniz getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	
	getSaleSummary: (saleId, saleCode) => {
		return new Promise((resolve, reject) => {
			request.get(`sale/summary/${saleId}/${saleCode}`, {}, (payload, status) => {
				if (status === 200 && payload?.sale) {
					resolve(payload);
				}
				else {
					reject(false);
				}
			});
		});
	},
	// Agreements
	getAgreement: (key, parameter) => {
		return new Promise((resolve, reject) => {
			request.get(
				`agreements/${key}${parameter ? `/${parameter}`: ''}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload) {
						resolve(payload);
					}
					else {
						reject(request?.data?.meta?.messages || 'Sözleşme getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	// Message
	postMessage: (formData) => {
		return new Promise((resolve, reject) => {
			request.post(
				`member/messages`,
				{...formData},
				function (payload, status, request) {
					if (status === 200) {
						resolve(request?.data?.meta?.messages.success ? request.data.meta.messages : { success: 'İletişim formunuzu aldık, size en kısa zamanda dönüş yapacağız.'});
					}
					else {
						reject(request?.data?.meta?.messages || 'Mesajınız gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getMessageSubjects: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages/subjects`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload?.subjects) {
						resolve(payload.subjects);
					}
					else {
						reject(request?.data?.meta?.messages || 'İletişim konuları getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getMessageCategories: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages/categories`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload?.categories) {
						resolve(payload.categories);
					}
					else {
						reject(request?.data?.meta?.messages || 'İletişim kategorileri getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getMessageOrders: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages/sales`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload?.sales) {
						resolve(payload.sales);
					}
					else {
						reject(request?.data?.meta?.messages || 'Siparişleriniz getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	
}

export default userServices;
