import request from 'pstv-commerce-tools/utilities/request';

const masterpassServices = {
	getInfo: (saleId) => {
		return new Promise((resolve, reject) => {
			request.post(`payment/masterpass/info`, {sale_id: saleId}, function(payload, status, request){
				if(payload) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Masterpass bilgileri alınırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		})
	},
	generateClientToken: (saleId) => {
		return new Promise((resolve, reject) => {
			request.get(`payment/masterpass/generate-client-token`, { sale_id: saleId }, function(payload, status, request){
				if(payload?.token) {
					resolve(payload.token);
				}
				else {
					reject(request?.data?.meta?.messages || 'Client token alınırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	generatePaymentToken: (saleID) => {
		return new Promise((resolve, reject) => {
			request.get(`payment/masterpass/generate-payment-token`, { sale_id: saleID, ratio_id: 46 }, function(payload, status, request){
				if(payload?.token) {
					resolve(payload.token);
				}
				else {
					reject(request?.data?.meta?.messages || 'Payment token alınırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	validatePayment: (saleID, successToken) => {
		return new Promise((resolve, reject) => {
			request.post(`payment/masterpass/commit-purchase`, {sale_id: saleID, token: successToken}, function(payload, status, request){
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Ödeme tamamlanırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
}

export default masterpassServices;
