/**
 * @param {object} category
 * @param {array} flattenedCategories
 * @returns {array}
 */
function flattenCategories(category, flattenedCategories = []) {
    if (!category) return []

    flattenedCategories.push(category);
  
    if (category.children && category.children.length > 0) {
      for (const child of category.children) {
        flattenCategories(child, flattenedCategories);
      }
    }
  
    return flattenedCategories;
}

/**
 * @param {object} obj
 * @param {number} mainCategoryId
 * @param {number} targetCategoryId
 * @returns {object | null} categoryObject
 */
export default function deepFindCategory(obj, mainCategoryId, targetCategoryId) {
    const mainCategory =  obj.find((categ) => categ.id === mainCategoryId)
    const flattenedCategoryArray = flattenCategories(mainCategory)
 
    return flattenedCategoryArray.find((subCateg) => subCateg.id === targetCategoryId) ?? null
}
