import { useMemo } from 'react';
import { useBasket } from '.';

export const useBasketProduct = (productSerial) => {
	const { indexedProducts, basketTimestamp } = useBasket();
	const basketProduct = useMemo(() => {
		return productSerial ? { ...indexedProducts[productSerial], basketTimestamp: basketTimestamp } : undefined;
	}, [indexedProducts, basketTimestamp])

	return basketProduct;
}