import { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";

// Context
import { paymentMethods, CheckoutContext, useMasterpass } from ".";
import { useBasket } from "/controllers/basket";
import { useNavigator } from "pstv-commerce-tools/utilities/navigator";
import { useModals } from "pstv-commerce-tools/utilities/modals";

// Controllers
import { useDeliveryInfo } from "/controllers/delivery";

// Services
import saleServices from "/services/sale";
import { useGlobalEvents } from "pstv-commerce-tools/utilities/global-events";

const computePaymentInfo = (paymentMethodValue) => {
    if (paymentMethodValue === "doorpayment") {
        return "PAYDOOR_CREDIT_CARD";
    } else {
        return false;
    }
};

export const CheckoutProvider = ({ children }) => {
    const { openModal, closeModal } = useModals();
    const { activeRoute, redirect } = useNavigator();
    const { selectedDelivery } = useDeliveryInfo();
    const { getBasketData } = useBasket();
    const { triggerEvent } = useGlobalEvents();

    const saleId = useRef(false);

    const [sale, setSale] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [touched, setTouched] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
        paymentMethods[0]
    );
    const [paymentInfo, setPaymentInfo] = useState(
        computePaymentInfo(paymentMethods[0].value)
    );
    const [selectedSlotId, setSelectedSlotId] = useState(false);

    const masterpass = useMasterpass({
        saleId: sale?.id,
        storeSaleId: sale?.store_sale_id,
        enabled: selectedPaymentMethod.value === "masterpass",
    });

    const [loading, busy] = useMemo(() => {
        const loading = !sale;
        return [
            loading,
            loading ||
                submitting ||
                selectedSlotId !== sale.delivery_calendarid,
        ];
    }, [sale, submitting, selectedSlotId]);

    useEffect(() => {
        if (
            selectedPaymentMethod.value === "masterpass" &&
            masterpass.accountStatus?.isLinked &&
            masterpass.accountStatus.hasCards &&
            masterpass.cards &&
            masterpass.cards.length === 1
        ) {
            setPaymentInfo(masterpass.cards[0]);
        }
    }, [masterpass, selectedPaymentMethod]);

    const getSale = () => {
        return new Promise((resolve, reject) => {
            saleServices
                .get(saleId.current)
                .then((sale) => {
                    if (
                        selectedDelivery.store.id === sale.storeid &&
                        sale.status === 0
                    ) {
                        setSelectedSlotId(sale.delivery_calendarid);

                        const newSale = {
                            ...sale,
                        };

                        setSale(newSale);
                        resolve(newSale);
                    } else {
                        window.localStorage.removeItem("active_sale_id");
                        reject(false);
                        redirect("basket");
                    }
                })
                .catch((e) => {
                    reject(e);
                    console.warn("Sale fetch error:", e);
                    redirect("basket");
                });
        });
    };

    const setSaleNote = (note) => {
        return new Promise((resolve, reject) => {
            saleServices
                .setNote(sale.id, note)
                .then(() => {
                    getSale()
                        .then(() => {
                            resolve(true);
                        })
                        .catch((feedback) => {
                            reject(feedback);
                        });
                })
                .catch((feedback) => {
                    reject(feedback);
                });
        });
    };

    const submitCheckout = (checkoutOptions) => {
        return new Promise((resolve, reject) => {
            const fail = (message) => {
                setSubmitting(false);
                setTouched(true);
                reject(message);
            };

            const completeSale = (saleID, saleCode) => {
                getBasketData()
                    .catch(() => {
                        redirect(
                            "checkoutSummary",
                            { id: saleID, code: saleCode },
                            { hard: true }
                        );
                    })
                    .then(() => {
                        redirect("checkoutSummary", {
                            id: saleID,
                            code: saleCode,
                        });
                    });
            };

            if (!paymentInfo) {
                fail("Devam etmek için bir ödeme yöntemi seçmelisiniz.");
            } else if (!sale.delivery_calendarid) {
                fail("Devam etmek için bir teslimat zamanı seçmelisiniz.");
            } else {
                setSubmitting(true);
                setTouched(true);

                triggerEvent("checkoutComplete", { sale });

                saleServices
                    .setOptions(saleId.current, checkoutOptions)
                    .then(() => {
                        if (selectedPaymentMethod.value === "doorpayment") {
                            saleServices
                                .completeDoorPayment(
                                    saleId.current,
                                    paymentInfo
                                )
                                .then((response) => {
                                    completeSale(
                                        response.sale_id,
                                        response.sale_code
                                    );
                                })
                                .catch((feedback) => {
                                    fail(feedback);
                                });
                        } else {
                            masterpass
                                .purchaseWithRegisteredCard(paymentInfo.name)
                                .then((response) => {
                                    if (response.validation) {
                                        openModal("payment", {
                                            mode: "validation",
                                            validationType: response.validation,
                                            title: "SMS Onayı",
                                            allowClose: true,
                                            otpData: {
                                                isPurchase: true,
                                                cardName: paymentInfo.name,
                                            },
                                            onClose: () => {
                                                setSubmitting(false);
                                            },
                                            onError: (feedback) => {
                                                closeModal();
                                                fail(feedback);
                                            },
                                            onSuccess: (payload) => {
                                                completeSale(
                                                    payload.sale_id,
                                                    payload.sale_code
                                                );
                                                closeModal();
                                            },
                                        });
                                    } else {
                                        completeSale(
                                            response.sale_id,
                                            response.sale_code
                                        );
                                    }
                                })
                                .catch((feedback) => {
                                    fail(feedback);
                                });
                        }
                    })
                    .catch((feedback) => {
                        fail(feedback);
                    });
            }
        });
    };

    // Initialize
    useEffect(() => {
        if (activeRoute?.key === "checkout" && selectedDelivery) {
            saleId.current = window.localStorage.getItem("active_sale_id");
            if (saleId.current) {
                getSale();
            } else if (!saleId.current) {
                redirect("basket");
            }
        } else {
            if (saleId.current) {
                setSale(false);
                setSubmitting(false);
                setTouched(false);
                setSelectedPaymentMethod(paymentMethods[0]);
                setPaymentInfo(computePaymentInfo(paymentMethods[0].value));
                setSelectedSlotId(false);
                saleId.current = false;

                if (activeRoute?.key === "checkout") {
                    redirect("basket");
                }
            }
        }
    }, [activeRoute, selectedDelivery]);

    // Reset Payment Info
    useEffect(() => {
        triggerEvent("checkoutSetPaymentMethod", {
            paymentMethod: selectedPaymentMethod,
        });
        setPaymentInfo(computePaymentInfo(selectedPaymentMethod?.value));
    }, [selectedPaymentMethod]);

    useEffect(() => {
        triggerEvent("checkoutSetPaymentMethod", {
            sale,
            paymentMethod: selectedPaymentMethod,
        });
    }, [sale, selectedPaymentMethod]);

    useEffect(() => {
        if (selectedSlotId) {
            saleServices
                .setSlot(saleId.current, selectedSlotId)
                .then(() => {
                    getSale();
                })
                .catch(() => {
                    getSale();
                });
        }
    }, [selectedSlotId]);

    return (
        <CheckoutContext.Provider
            value={{
                sale: sale,
                loading,
                busy,
                submitting,
                setSaleNote,
                submitCheckout,
                store: selectedDelivery.store,
                address: selectedDelivery.address,
                invoiceAddress: sale?.summary?.customer_invoice,
                selectedPaymentMethod,
                setSelectedPaymentMethod,
                paymentInfo,
                setPaymentInfo,
                masterpass,
                selectedSlotId,
                setSelectedSlotId,
                touched,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

CheckoutProvider.propTypes = {
    children: PropTypes.node,
};
