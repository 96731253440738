const registeredModals = {
	options: () => (import('./options')),
	confirm: () => (import('./confirm')),
	message: () => (import('./message')),
	address: () => (import('./address')),
	auth: () => (import('./auth')),
	agreement: () => (import('./agreement')),
	payment: () => (import('./payment')),
	contact: () => (import('./contact')),
	deliverySlots: () => (import('./delivery-slots')),
	orderNote: () => (import('./order-note')),
	campaignDetail: () => (import('./campaign-detail')),
	productGallery: () => (import('./product-gallery')),
}

export default registeredModals;