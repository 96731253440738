import request from 'pstv-commerce-tools/utilities/request';

const siteServices = {
	// Categories
	getCategories: () => {
		return new Promise((resolve, reject) => {
			request.get('categories/menu', {}, (payload, status, request) => {
				if (payload?.menu) {
					resolve(payload.menu);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Banners
	getBanners: (bannerID) => {
		return new Promise((resolve, reject) => {
			request.get(`banners/${bannerID}`, {}, (payload, status, request) => {
				if (payload && payload.banners) {
					resolve(payload.banners);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Campaigns
	getCampaigns: () => {
		return new Promise((resolve, reject) => {
			request.get('campaigns/contents', {}, (payload, status, request) => {
				if (payload?.campaigns) {
					resolve(payload.campaigns);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCampaignDetail: (campaignID) => {
		return new Promise((resolve, reject) => {
			request.get(`campaigns/contents/${campaignID}`, {}, (payload, status, request) => {
				if (payload?.campaign) {
					resolve(payload.campaign);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCampaignProducts: (campaignID) => {
		return new Promise((resolve, reject) => {
			request.get(`campaigns/content-products/${campaignID}`, {}, (payload, status, request) => {
				if (payload?.products) {
					resolve(payload.products);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Brands
	getFeaturedBrands: (limit = false) => {
		return new Promise((resolve, reject) => {
			request.get('brands', { featured: 1 }, (payload, status, request) => {
				if (payload?.brands) {
					resolve(limit ? payload.brands.slice(0, 30) : payload.brands);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Showcases
	getShowcase: (showcaseID, showOutOfStock) => {
		return new Promise((resolve, reject) => {
			request.get(`showcases/${showcaseID}${!showOutOfStock ? '?stock=1' : ''}`, {}, (payload, status, request) => {
				if (payload?.showcase) {
					resolve(payload.showcase);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Faqs
	getFaqs: () => {
		return new Promise((resolve, reject) => {
			request.get(`faqs`, {}, (payload, status, request) => {
				if (payload?.faqs) {
					resolve(payload.faqs);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getFaqDetail: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`faqs/${id}`, {}, (payload, status, request) => {
				if (payload?.faq) {
					resolve(payload.faq);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Address Parts
	getCities: () => {
		return new Promise((resolve, reject) => {
			request.get(`sys/cities`, {}, (payload, status, request) => {
				if (payload?.cities) {
					resolve(payload.cities);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getDistricts: (cityID) => {
		return new Promise((resolve, reject) => {
			request.get(`sys/cities/${cityID}`, {}, (payload, status, request) => {
				if (payload?.districts) {
					resolve(payload.districts);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Search
	getPopularSearches: () => {
		return new Promise((resolve, reject) => {
			request.get(`search/popular`, {}, (payload, status, request) => {
				if (payload?.keywords && payload.products) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Sys
	getDeliveryLimits: () => {
		return new Promise((resolve, reject) => {
			request.get(`sys/parameters/group/delivery`, {}, (payload, status, request) => {
				if (payload?.delivery_limits) {
					resolve(payload.delivery_limits);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getHomeSlogan: () => {
		return new Promise((resolve, reject) => {
			request.get(`sys/parameters/site_parameter/home_slogan`, {}, (payload, status, request) => {
				if (payload?.site_parameter?.description) {
					resolve(payload.site_parameter.description);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getHomeShowcases: () => {
		return new Promise((resolve, reject) => {
			request.get(`active-showcases`, {}, (payload, status, request) => {
				if (payload?.['active-showcases']) {
					resolve(payload['active-showcases']);
				}
				else {
					reject(request);
				}
			});
		});
	},

}

export default siteServices;
