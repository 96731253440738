import request from 'pstv-commerce-tools/utilities/request';

const saleServices = {
	prepare: () => {
		return new Promise((resolve, reject) => {
			request.get(`sale/prepare`, {}, (payload, status) => {
				if (status === 200 && payload?.sale?.id) {
					resolve(payload.sale);
				}
				else {
					reject(request?.data?.meta?.messages || 'Siparişiniz oluşturulurken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	updateInvoiceType: (saleId, type) => {
		return new Promise((resolve, reject) => {
			request.post(`sale/${saleId}/update-invoice-type?type=${type}`, {}, (payload, status) => {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Fatura tipini belirlerken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	get: (saleId) => {
		return new Promise((resolve, reject) => {
			request.get(`sale/${saleId}`, {}, (payload, status) => {
				if (status === 200 && payload?.sale) {
					resolve(payload.sale);
				}
				else {
					reject(false);
				}
			});
		});
	},
	setAddresses: (saleId, deliveryId, invoiceId) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleId}`,
				{
					member_delivery_id: deliveryId,
					member_invoice_id: invoiceId,
				},
				function (payload, status) {
					if (status === 200) {
						resolve({
							'sale-id': saleId,
							'delivery-id': deliveryId,
							'invoice-id': invoiceId
						});
					}
					else {
						reject('Teslimat adresi işlenirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				}
			);
		});
	},
	setSlot: (saleId, slotId) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleId}/slot`,
				{
					delivery_calendar_id: slotId,
				},
				function (payload, status) {
					if (status === 200) {
						resolve();
					}
					else {
						reject('Teslimat zamanı seçimi işlenirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				},
				{ showMessages: true }
			);
		});
	},
	setNote: (saleId, note) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleId}/set-note`,
				{
					sale_note: note,
				},
				function (payload, status) {
					if (status === 200) {
						resolve();
					}
					else {
						reject('Sipariş notunuz kaydedilirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				},
				{ showMessages: true }
			);
		});
	},
	cancelSale: (saleId) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleId}/cancel`,
				false,
				function (payload, status) {
					if (status === 200) {
						resolve();
					}
					else {
						reject('Sipariş iptal edilirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				},
				{ showMessages: true }
			);
		});
	},
	setOptions: (saleId, options = {}) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleId}/options`,
				{
					non_contact: false,
					no_bell: false,
					...options,
				},
				function (payload, status) {
					if (status === 200) {
						resolve();
					}
					else {
						reject('Sipariş seçenekleri kaydedilirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				},
				{ showMessages: false }
			);
		});
	},
	completeDoorPayment: (saleId, paymentType) => {
		return new Promise((resolve, reject) => {
			request.post(
				`payment/door-pay`,
				{
					sale_id : saleId,
					door_payment_type : paymentType
				},
				function (payload, status) {
					if (status === 200 && payload?.sale_code && payload?.sale_id) {
						resolve(payload);
					}
					else {
						reject('Sipariş tamamlanırken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
					}
				},
				{ showMessages: true }
			);
		});
	},
}

export default saleServices;
