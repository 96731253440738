import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { setHeaders } from 'pstv-commerce-tools/utilities/request'

// Context
import { AuthContext } from 'pstv-commerce-tools/utilities/auth';
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useStorage } from 'pstv-commerce-tools/utilities/storage';
import { useModals } from 'pstv-commerce-tools/utilities/modals';

// Services
import userServices from '/services/user';

export const AuthProvider = ({ children }) => {
	const { authToken, guestToken, setData } = useStorage();
	const [initialized, setInitialized] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [userData, setUserDataRaw] = useState(null);

	const { setError } = useGlobalState();
	const { openModal } = useModals();

	const setUserData = (userData) => {
		if(userData) {
			setLoggedIn(true);
			setUserDataRaw(userData);
		}
		else {
			setLoggedIn(false);
			setUserDataRaw(null)
		}
	}

	const softLogin = (token) => {
		return new Promise((resolve, reject) => {
			setHeaders({
				'authorization': `Bearer ${token}`,
			});
			userServices.getUserData().then((userData) => {
				setUserData(userData);
				setInitialized(true);
				setData('authToken', token);
				resolve(userData);
			}).catch((e) => {
				setHeaders({
					'authorization': false,
				});
				console.warn('User initialization error:', e);
				reject(e);
			})
		});
	}

	const getUserData = () => {
		return new Promise((resolve, reject) => {
			userServices.getUserData().then((userData) => {
				setUserData(userData);
				resolve(userData);
			}).catch((e) => {
				reject(e);
			})
		})
	}

	const hardLogout = () => {
		userServices.logout().then(() => {}).catch(() => {});
		setData('authToken', false);
		setData('guestToken', false);
	}

	const logout = () => {
		return new Promise((resolve) => {
			openModal('confirm', {
				message: 'Çıkış yapmak istediğinizden emin misiniz?',
				onConfirm: () => {
					hardLogout();
					resolve();
				}
			});
		})
	}

	useEffect(() => {
		if(!guestToken) {
			userServices.getGuestToken().then((newGuestToken) => {
				setData('guestToken', newGuestToken)
			}).catch((e) => {
				console.warn('Guest-token initialization error:', e);
				setError();
			})
		}
	}, [guestToken])

	useEffect(() => {
		if(!authToken) {
			setHeaders({
				'authorization': false,
			});

			if(loggedIn) {
				setUserData(false);
			}
			else {
				setInitialized(true);
				setUserData(false);
			}
		}
		else if(!loggedIn && guestToken && authToken) {
			getUserData().then(() => {
				setInitialized(true);
			}).catch((e) => {
				setData('authToken', false);
				setData('guestToken', false);
				console.warn('User auth error:', e);
			})
		}
	}, [authToken, guestToken, loggedIn])

	return (
		<AuthContext.Provider
			value={{
				initialized,
				loggedIn,
				userData,
				getUserData,
				logout,
				softLogin,
				setUserData,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.node,
}
