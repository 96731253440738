import loadable from '@loadable/component'

export default {
	home: {
		path: '/',
		exact: true,
		layout: 'base',
		layoutClass: 'home',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/home')),
		showSlogan: true,
	},
	basket: {
		path: '/siparis',
		exact: true,
		requiresLogin: true,
		layout: 'checkout',
		layoutClass: 'basket',
		head: {
			title: 'Sepetim',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/basket')),
	},
	checkout: {
		path: '/siparis/odeme',
		requiresLogin: true,
		layout: 'checkout',
		layoutClass: 'checkout',
		hideAddressBar: true,
		previousPage: 'home',
		head: {
			title: 'Siparişim',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout')),
	},
	checkoutSummary: {
		path: '/siparis/ozeti/:id/:code',
		requiresLogin: true,
		layout: 'checkout',
		layoutClass: 'checkout-summary',
		hideAddressBar: true,
		previousPage: 'home',
		head: {
			title: 'Sipariş Özeti',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout-summary')),
	},
	category: {
		path: '/:slug-c-:id/:query?',
		layout: 'base',
		layoutClass: 'category',
		head: {
			title: 'Kategori',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/category')),
	},
	search: {
		path: '/arama/:search/:query?',
		layout: 'base',
		layoutClass: 'search',
		head: {
			title: 'Arama',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/search')),
	},
	customList: {
		path: '/:slug-cl-:id/:query?',
		layout: 'base',
		layoutClass: 'customlist',
		head: {
			title: 'Liste',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/customlist')),
	},
	brand: {
		path: '/:slug-b-:id/:query?',
		layout: 'base',
		layoutClass: 'brand',
		head: {
			title: 'Marka Ürünleri',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/brand')),
	},
	productDetail: {
		path: '/:slug-p-:id',
		layout: 'base',
		layoutClass: 'product-detail',
		head: {
			title: 'Ürün',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/product-detail')),
	},
	login: {
		path: '/giris-yap',
		layout: 'base',
		layoutClass: 'login',
		head: {
			title: 'Giriş Yap',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/login')),
	},
	account: {
		path: '/hesabim',
		requiresLogin: true,
		redirect: {
			key: 'account.info',
			params: {}
		},
		children: {
			orders: {
				path: '/hesabim/siparisler',
				exact: true,
				layout: 'account',
				layoutClass: 'account-orders',
				requiresLogin: true,
				head: {
					title: 'Siparişlerim',
				},
				component: loadable(() => import('/views/pages/account/orders')),
				menuIcon: 'order',
			},
			orderDetail: {
				path: '/hesabim/siparisler/detay/:id/:code',
				layout: 'account',
				layoutClass: 'account-order-detail',
				requiresLogin: true,
				head: {
					title: 'Sipariş Detayı',
				},
				component: loadable(() => import('/views/pages/account/order-detail')),
				hideFromMenu: true,
			},
			info: {
				path: '/hesabim/bilgi',
				layout: 'account',
				layoutClass: 'account-info',
				requiresLogin: true,
				head: {
					title: 'Hesap Bilgilerim',
				},
				component: loadable(() => import('/views/pages/account/info')),
				menuIcon: 'user-info',
			},
			addresses: {
				path: '/hesabim/adreslerim',
				layout: 'account',
				layoutClass: 'account-addresses',
				requiresLogin: true,
				head: {
					title: 'Adreslerim',
				},
				component: loadable(() => import('/views/pages/account/addresses')),
				menuIcon: 'address',
			},
			wishlist: {
				path: '/hesabim/favori-urunlerim',
				layout: 'account',
				layoutClass: 'account-wishlist',
				requiresLogin: true,
				head: {
					title: 'Favori Ürünlerim',
				},
				component: loadable(() => import('/views/pages/account/wishlist')),
				menuIcon: 'heart-empty',
			},
		}
	},
	faqs: {
		path: '/sikca-sorulan-sorular',
		exact: true,
		layout: 'account',
		layoutClass: 'faqs',
		head: {
			title: 'Yardım / Hakkında',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/faqs')),
	},
	faqDetail: {
		path: '/sikca-sorulan-sorular/:slug-s-:id',
		layout: 'account',
		layoutClass: 'faq-detail',
		head: {
			title: 'Yardım / Hakkında',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/faq-detail')),
	},
	campaigns: {
		path: '/kampanyalar',
		exact: true,
		layout: 'base',
		layoutClass: 'campaigns',
		head: {
			title: 'Metro Online Kampanyaları',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/campaigns')),
	},
	campaignDetail: {
		path: '/kampanyalar/:id',
		layout: 'base',
		layoutClass: 'campaigns',
		head: {
			title: 'Metro Online Kampanyaları',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/campaign-detail')),
	},
	agreement: {
		path: '/sozlesme/:slug/:parameter?',
		exact: true,
		layout: 'base',
		layoutClass: 'agreement',
		head: {
			title: 'Sözleşme',
		},
		component: loadable(() => import('/views/pages/agreement')),
	},
	brands: {
		path: '/markalar',
		layout: 'simple',
		layoutClass: 'brands',
		head: {
			title: 'Markalar',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/brands')),
	},
	// brands: {
	// 	path: '/markalar',
	// 	layout: 'simple',
	// 	layoutClass: 'brands',
	// 	head: {
	// 		title: 'Markalar',
	// 	},
	// 	component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/brands')),
	// },
	notFound: {
		path: '*',
		layout: 'base',
		layoutClass: 'error notfound',
		status: 404,
		head: {
			title: 'Sayfa Bulunamadı',
		},
		component: loadable(() => import('/views/pages/not-found')),
	},
	error: {
		path: '*',
		layout: 'base',
		layoutClass: 'error',
		status: 500,
		head: {
			title: 'Bir Hata İle Karşılaşıldı',
		},
		component: loadable(() => import('/views/pages/error')),
	}
}