import { useEvent } from "pstv-commerce-tools/utilities/global-events";

// Context
import { useAuth } from 'pstv-commerce-tools/utilities/auth';

export const GtmEventsController = () => {
	const { userData } = useAuth();

	const sendGtmEvent = (eventKey, params) => {
		if(process.config.gtm?.id && window.dataLayer) {
			window.dataLayer.push({
				event: eventKey, 
				ecommerce: {
					...(userData ? {
						customerContactKey: `${userData.erp_home_storeid}00${userData.id}`
					}: {}),
					...params,
				},
			});
		}
	}

	const getGTMProduct = (product) => {
		return {
			id: product.id,
			item_name: product.title,
			item_id: product.product_code,
			price: String(product.price.original),
			item_brand: product.brand ?? '',
			item_category: (product.category_breadcrumb && product.category_breadcrumb.length > 0) ? product.category_breadcrumb[product.category_breadcrumb.length - 1].name : '', // SENTRY
			...(product.category_breadcrumb ?? []).reduce((categories, category) => {
				return {
					...categories,
					[`item_category${Object.keys(categories).length ? Object.keys(categories).length : ''}`]: category.name,
				}
			}, { }),
			item_variant: product.serial_title,
			quantity: 1,
		}
	}

	const productViewEvent = ({ product } = {}) => {
		if(product) {
			sendGtmEvent('view_item', {
				items: [
					{
						...getGTMProduct(product),
						quantity: 1,
					}
				] 
			});
		}
	};

	const productBasketAddEvent = ({ product, quantity } = {}) => {
		if(product) {
			sendGtmEvent('add_to_cart', {
				items: [
					{
						...getGTMProduct(product),
						quantity: quantity,
					}
				] 
			});
		}
	};

	const productBasketRemoveEvent = ({ product, quantity } = {}) => {
		if(product) {
			sendGtmEvent('remove_from_cart', {
				items: [
					{
						...getGTMProduct(product),
						quantity: quantity,
					}
				] 
			});
		}
	};
	
	const salePrepareEvent = ({ sale } = {}) => {
		if(sale) {
			sendGtmEvent('begin_checkout', {
				sale_id: sale.id,
				items: sale.products.map(product => (
					{
						...getGTMProduct(product),
						quantity: parseFloat(product.basket_quantity),
					}
				)),
			});
		}
	};
	
	const saleSetAddressEvent = ({ sale } = {}) => {
		if(sale) {
			sendGtmEvent('add_shipping_info', {
				shipping_tier: 'Eve Teslimat',
				items: sale.products.map(product => (
					{
						...getGTMProduct(product),
						quantity: parseFloat(product.basket_quantity),
					}
				)),
			});
		}
	};
	
	const checkoutSetPaymentMethodEvent = ({ sale, paymentMethod } = {}) => {
		if(sale && paymentMethod) {
			sendGtmEvent('add_payment_info', {
				payment_type: paymentMethod.label,
				items: sale.products.map(product => (
					{
						...getGTMProduct(product),
						quantity: parseFloat(product.basket_quantity),
					}
				)),
			});
		}
	};
	
	const checkoutCompleteEvent = ({ sale } = {}) => {
		if(sale) {
			sendGtmEvent('purchase', {
				currency: sale.summary.grand_total.currency,
				value: sale.summary.grand_total.original,
				tax: sale.summary.total_tax.original,
				shipping: sale.summary.deliveryProduct ? sale.summary.deliveryProduct.price.original : 0,
				transaction_id: sale.id,
				// affiliation: '',
				coupon: '',
				items: sale.products.map(product => (
					{
						...getGTMProduct(product),
						quantity: parseFloat(product.basket_quantity),
					}
				)),
			});
		}
	};

	const productWishlistAddEvent = ({ product } = {}) => {
		if(product) {
			sendGtmEvent('add_to_wishlist', {
				items: [{
					...getGTMProduct(product),
				}],
			});
		}
	};

	const productWishlistRemoveEvent = ({ product } = {}) => {
		if(product) {
			sendGtmEvent('remove_from_wishlist', {
				items: [{
					...getGTMProduct(product),
				}],
			});
		}
	};


	useEvent('productView', productViewEvent);
	useEvent('productBasketAdd', productBasketAddEvent);
	useEvent('productBasketRemove', productBasketRemoveEvent);
	useEvent('salePrepare', salePrepareEvent);
	useEvent('saleSetAddress', saleSetAddressEvent);
	useEvent('checkoutSetPaymentMethod', checkoutSetPaymentMethodEvent);
	useEvent('checkoutComplete', checkoutCompleteEvent);
	useEvent('productWishlistAdd', productWishlistAddEvent);
	useEvent('productWishlistRemove', productWishlistRemoveEvent);
	
	return null;
}
