const zeroPrice = {
	"original": 0,
	"original_str": "0,00",
	"whole": 0,
	"whole_str": "0",
	"fraction": 0,
	"fraction_str": "00",
	"currency": "TL",
	"currency_symbol": "\u20ba",
	"decimal_seperator": ",",
	"thousands_separator": "."
};

export const defaultState = {
	initialized: false,
	busy: false,
	summary: {
		"services": [],
		"price": zeroPrice,
		"basket_quantity": 0,
		"service_total": zeroPrice,
		"total_tax": zeroPrice,
		"promotion_code": null,
		"installment": null,
		"grand_total": zeroPrice,
		"price_taxless": zeroPrice,
		"given_cargo_day": null,
	},
	deliveryLimits: {
		home_delivery_fee: zeroPrice,
		home_delivery_free_limit: zeroPrice,
		home_delivery_min_basket: zeroPrice,
		market_delivery_min_basket: zeroPrice,
	},
	updating: false,
	products: [],
	indexedProducts: {},
};